.header {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  padding: 0 34px;

  background: var(--color-blue);
}

.nav {
  width: 100%;
  max-width: 1366px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & a {
    height: 100%;
    text-decoration: none;
    color: white;
    &:active {
      outline: none;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.link {
  height: 100%;
  display: flex;
  align-items: center;
}

