.layout {
  display: flex;
  justify-content: center;
  padding: 34px;

  & > :first-child {
    width: 100%;
    max-width: 1366px;
  }
}
