.container {
  width: 100%;
  position: relative;
}

.small {
  .input {
    padding: 4px 8px;
    font-size: 14px;
    line-height: 22px;
    min-height: 32px;
    border-radius: 6px;
  }

  .withLabel {
    padding: 13px 8px 5px;
  }

  .label {
    top: 4px;
    left: 8px;

    font-size: 10px;
    line-height: 14px;
  }
}

.input {
  width: 100%;
  min-height: 54px;
  padding: 4px 16px;
  border: 1px solid #CECED6;
  border-radius: 8px;

  font-size: 18px;
  line-height: 24px;
  font-weight: 400;

  &:focus {
    outline: none;
    border: 1px solid rgb(126, 126, 134);
  }

  &:disabled {
    color: #999;
    background: #F5F5F5;
    cursor: default;
  }
}

.withLabel {
  padding: 20px 16px 4px;
}

.label {
  position: absolute;
  top: 4px;
  left: 16px;

  color: #A0A0AD;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}
