.modal{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  background-color: rgba(0, 0, 0, 0.6);
}

.content {
  display: contents;
}