.form {
  max-width: 892px !important;
  padding: 24px;
  background: var(--color-background-gray);
  border-radius: 16px;
}

.title {
  margin-bottom: 16px;

  color: var(--color-text);
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.input {
  flex: 1 0 0;
}

.createBtn, .closeBtn {
  padding: 10px 60px;
  border-radius: 8px;
  cursor: pointer;

  &:disabled {
    filter: grayscale(0.8) opacity(0.6);
    cursor: default;
  }
}

.createBtn {
  background: var(--color-blue);
  border: none;
  color: white;
}

.closeBtn {
  margin-right: 20px;
  background: white;
  border: 1px solid #CECED6;
}
