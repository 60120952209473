.container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.disabled {
  opacity: 0.4;
  cursor: default;
}

.radio {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: white;
  border: 1px solid #CECED6;
  border-radius: 16px;
}

.radioMarker {
  width: 8px;
  height: 8px;

  background: var(--color-blue);
  border-radius: 8px;
}
