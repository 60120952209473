.container {
  width: 100%;
  position: relative;
}

.input {
  width: 100%;
  padding: 22px 16px 6px;
  border: 1px solid #CECED6;
  border-radius: 8px;

  font-size: 15px;
  line-height: 24px;
  font-weight: 400;

  &:focus {
    outline: none;
    border: 1px solid rgb(126, 126, 134);
  }
}

.label {
  position: absolute;
  top: 6px;
  left: 16px;

  color: #A0A0AD;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}
