.tableColTitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
}

.tableColTitleArrows {
  display: flex;
  flex-direction: column;
  margin-left: 6px;

  & > :last-child {
    margin-top: 2px;
    transform: rotate(180deg);
  }
}

.activeArrow {
  fill: var(--color-blue);
}
