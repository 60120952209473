.form {
  width: 100%;
  max-width: 892px !important;
  padding: 24px;
  background: var(--color-background-gray);
  border-radius: 16px;
}

.title {
  margin-bottom: 16px;

  color: var(--color-text);
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.input {
  flex: 1 0 0;
  width: 0;
}

.addNewBtn {
  height: max-content;
  padding: 12px 8px;

  background: white;
  border: 1px solid rgba(205, 205, 213, 0.8);

  font-size: 18px;

  & > img {
    width: 12px;
    height: 12px;
    object-fit: contain;
  }
}

.closeBtn {
  padding: 10px 60px !important;
  margin-right: 20px;

  background: white !important;
  border: 1px solid #CECED6 !important;
  color: var(--color-text) !important;
}
