.container {
  display: flex;
}

.pageBtn, .arrowBtn {
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  background: white;
}

.pageBtn, .threeDots {
  padding: 8px 16px;
}

.threeDots {
  align-self: center;
  cursor: default;
}

.pageBtn:hover, .activePage {
  background: var(--color-blue);
  color: white;
}

.arrowBtn {
  display: flex;
  align-items: center;
  color: white;
  background: var(--color-blue);
}

.prevBtn:before, .nextBtn:after {
  content: '';
  width: 20px;
  height: 20px;
  display: block;
}

.prevBtn {
  padding: 8px 16px 8px 8px;

  &:before {
    margin-right: 4px;
    transform: rotate(180deg);
    background: url("/assets/img/arrow.svg") center / 6px 10px no-repeat;
  }
}

.nextBtn {
  padding: 8px 8px 8px 16px;

  &:after {
    margin-left: 4px;
    background: url("/assets/img/arrow.svg") center / 6px 10px no-repeat;
  }
}
