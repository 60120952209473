.titleContainer {
  display: flex;
  margin-bottom: 32px;
}

.title {
  margin-bottom: 8px;

  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  color: var(--color-text);
}

.youModerator {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;

  color: #737381;
}

.addRule {
  margin-left: auto;

  font-weight: 400 !important;
  font-size: 15px !important;
}

.titleTable {
  display: flex;
  width: 100%;
  justify-content: space-around;

  font-weight: 700;
  margin-bottom: 20px;
}

.table {
  border-collapse: collapse;
  margin-bottom: 20px;
  table-layout: fixed;

  & th {
    padding: 0 12px 12px;
  }
}

.tableLine {
  & > :nth-child(n) {
    padding: 18px 12px;
    border: 1px solid rgba(206, 206, 214, 0.8);
  }
}

.priceId {
  width: 1%;
  white-space: nowrap;
  color: var(--color-blue);
  cursor: pointer;
}

.delete {
  width: 20px;
  height: 20px;
  background: url("/assets/img/delete.svg") center / 100% 100%;
  cursor: pointer;
}
