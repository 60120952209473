.container {
  position: relative;
}

.small {
  .btn {
    padding: 4px 18px 4px 8px;
    border-radius: 6px;
    font-size: 14px;

    &:after {
      width: 10px;
      height: 6px;

      right: 10px;
    }
  }

  .withLabel {
    padding: 13px 18px 5px 8px;
  }

  .label {
    top: 4px;
    left: 8px;

    font-size: 10px;
    line-height: 12px;
  }

  .searchInput {
    & > input {
      padding: 4px 30px 4px 8px !important;
    }

    &:after {
      width: 16px;
      height: 16px;

      top: 50%;
      right: 6px;
    }
  }

  .options {
    border-radius: 6px;
  }

  .option, .no_data {
    padding: 4px 8px;
  }
}

.btn {
  width: 100%;
  display: flex;
  padding: 12px 32px 12px 16px;
  position: relative;

  background: white;
  border: 1px solid rgba(205, 205, 213, 0.8);
  border-radius: 8px;

  font-size: 18px;
  text-align: left;
  white-space: nowrap;

  cursor: pointer;

  &:after {
    content: '';
    width: 10px;
    height: 6px;

    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%) rotate(180deg);

    background: url("/assets/img/arrowFilled.svg") center / contain no-repeat;
  }

  &:disabled {
    background: #F5F5F5;
    cursor: default;
  }
}

.clearBtn {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;

  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  z-index: 1;

  background: var(--color-blue);
  border: none;
  border-radius: 20px;
  cursor: pointer;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.withLabel {
  padding: 20px 32px 4px 16px;
}

.value, .placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
}

.label {
  position: absolute;
  top: 4px;
  left: 16px;

  color: #A0A0AD;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.placeholder {
  opacity: 0.4;
}

.open:after {
  transform: translateY(-50%);
}

.dropDawnContainer {
  min-width: 100%;
  max-height: 280px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  position: absolute;
  bottom: -4px;
  left: 0;
  transform: translateY(100%);
  z-index: 1;
}

.searchInput {
  margin-bottom: 4px;
  position: relative;

  & > input {
    padding: 4px 30px 4px 16px;
  }

  &:after {
    content: '';
    width: 20px;
    height: 20px;
    display: block;

    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);

    opacity: 0.4;
    background: url("/assets/img/search.svg") center / contain no-repeat;
  }
}

.options {
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  background: white;
  border: 1px solid rgba(205, 205, 213, 0.8);
  border-radius: 8px;
}

.option, .no_data {
  width: 100%;
  padding: 10px 16px;

  border: none;
  background: none;
  text-align: left;
  white-space: nowrap;
}

.option {
  cursor: pointer;

  &:hover {
    background: #f3f3f6;
  }
}

.no_data {
  opacity: 0.6;
  cursor: default;
}
