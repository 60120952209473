//Input Checked

.switch {
  position: relative;
  display: flex;
  // width: 46px;
  height: 24px;
}

.switch:focus, .switch:active, .switch:checked {
  -webkit-appearance: none !important;
  appearance: none !important;
  outline:none !important;
  border: none !important;
  box-shadow: none !important;
  -webkit-tap-highlight-color:  transparent;
}
  /* Hide default HTML checkbox */
.switch .autoHistoryCheeckBox {display:none; outline:none; border: 0; outline: 0 !important;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  border-radius: 40px;
  width: 46px;
  height: 24px;
  top: 0;
  //left: 76px;
  right: 0;
  bottom: 0;
  border: 1px solid #CECED6;
  // background: rgba(224, 207, 226, 0.3);
  // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), inset 0px 1px 0px rgba(224, 207, 226, 0.3), inset 0px -3px 5px rgba(224, 207, 226, 0.6);
  border-radius: 13px;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  left: 5px;
  bottom: 2px;
  background: #3C3C46;
  background-position: center center;
  background-size: cover;
  border-radius: 40px;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:after{
  margin-left: 26px;
  line-height: 34px;
  -webkit-transition: .4s;
  transition: .4s;
}

.autoHistoryCheeckBox:checked + .slider {
  height: 24px;
  // background: linear-gradient(180deg, #4a2bfa 5.21%, #286ddd 94.79%), #115eee;
  background: white;
}

.autoHistoryCheeckBox:focus + .slider {
  border: 2px solid #FF7777;
  outline:none !important;
}

.autoHistoryCheeckBox:focus {
  outline:none !important;
}

.autoHistoryCheeckBox:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(18px);
}

.autoHistoryCheeckBox:checked  + .slider:before{
    background: #0063F7;
}

.autoHistoryCheeckBox:checked + .slider:after {
  margin-left: -30px;
  line-height: 34px;
  -webkit-transition: .4s;
  transition: .4s;
}

.autoHistoryCheeckBoxDisabled {
  pointer-events: none;
  opacity: 0.4;
}
