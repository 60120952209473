.btn {
  padding: 10px 25px;

  background: var(--color-blue);
  border: none;
  border-radius: 8px;

  color: white;
  cursor: pointer;

  &:disabled {
    cursor: default;
    filter: grayscale(0.8) opacity(0.6);
  }
}
