.bg {
  width: 100%;
  height: 100%;
  display: flex;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  background: rgba(0, 0, 0, 0.4);
}

.closeBtn {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-top: 16px;
  margin-right: 16px;
  background: white;
  border-radius: 50%;
  cursor: pointer;

  & > img{
    width: 18px;
    height: 18px;
  }
}

.popup {
  width: 420px;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 24px;
}

.title{
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.minTitle{
  margin-bottom: 12px;

  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}

.itemContainer{
  margin-bottom: 24px;
}

.error{
  margin-top: auto;
  margin-bottom: 8px;
  color: red;
}

.checkBox{
  // width: max-content;
  display: flex;
  margin-left: 46px;
}
