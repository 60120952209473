.page {
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

.title {
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 8px;
}

.newRuleBtn {
  padding: 10px 25px;
  background: var(--color-blue);
  border: none;
  border-radius: 8px;

  color: white;
  cursor: pointer;

  &:disabled {
    filter: grayscale(0.8) opacity(0.6);
    cursor: default;
  }
}

.filters {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 16px;
  align-items: center;
  margin-bottom: 18px;
}

.tableContainer {
  position: relative;
  width: 100%;
  min-height: 90px;
  margin-bottom: 18px;
}

.tableLoader {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.05);

  font-size: 38px;
  text-transform: uppercase;
  color: var(--color-blue);
}

.tableLoaderSpinner {
  width: 60px;
  height: 60px;
  background: none;
}

.tableTitle {
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 28px;
}

.table {
  width: 100%;
  border-collapse: collapse;

  & th {
    padding: 0 12px 12px;
  }

  & td {
    padding: 18px 12px;
    border: 1px solid rgba(206, 206, 214, 0.8);
  }
}

.ruleId {
  color: var(--color-blue);
  cursor: pointer;
}

.deleteBtn {
  width: 20px;
  height: 20px;

  background: none;
  border: none;

  cursor: pointer;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.pagination {
  width: fit-content;
  margin-left: auto;
}
