.titleContainer {
  display: flex;
  margin-bottom: 32px;
}

.title {
  margin-bottom: 8px;

  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  color: var(--color-text);
}

.youModerator {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;

  color: #737381;
}

.addRule {
  margin-left: auto;

  font-weight: 400 !important;
  font-size: 15px !important;
}

.rulesModeration {
  margin-bottom: 16px;

  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
}

.tableContainer {
  position: relative;
  width: 100%;
  min-height: 90px;
  margin-bottom: 18px;
}

.tableLoader {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.05);

  font-size: 38px;
  text-transform: uppercase;
  color: var(--color-blue);
}

.tableLoaderSpinner {
  width: 60px;
  height: 60px;
  background: none;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.tableTitleLine {
  & > :nth-child(n) {
    padding: 0 12px 12px;
    text-align: start;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
  }
}

.tableLine {
  width: 100%;

  & > :nth-child(n) {
    padding: 18px 12px;
    border: 1px solid rgba(206, 206, 214, 0.8);
  }
}

.tableId {
  width: fit-content;
  color: var(--color-blue);
  cursor: pointer;
}

.tableType {
  width: 136px;
}

.tableNewUsed {
  width: 136px;
}

.tableMake {
  min-width: 208px;
}

.tableModel {
  min-width: 208px;
}

.tableCondition {
  width: 104px;
}

.tablePriceType {
  width: 157px;
}

.tableRuleStatus {
  min-width: 86px;
}

.pagination {
  margin-left: auto;
}

.delete {
  width: 20px;
  height: 20px;
  background: url("/assets/img/delete.svg") center / 100% 100%;
  cursor: pointer;
}
