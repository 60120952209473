*,
p,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
  "Helvetica Neue", sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --color-text: #1E1E24;
  --color-background-gray: #f3f3f6;
  --color-blue: #0063F7;

  overflow-y: scroll;
  color: var(--color-text);
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  color: var(--color-text);
}

table{
  border-spacing: 0;
}
