.page {}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.title {
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 8px;
  margin-right: 16px;
}

.fixesBtn {
  height: max-content;
  padding: 4px 25px !important;
  background: white !important;
  border: 1px solid var(--color-blue) !important;
  border-radius: 4px;
  color: black !important;
}

.filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;

  & > label {
    margin-right: 8px;
    white-space: nowrap;
  }
}

.clearDateBtn {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;

  background: var(--color-blue);
  border: none;
  border-radius: 16px;
  cursor: pointer;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.tableContainer {
  position: relative;
  width: 100%;
  min-height: 90px;
  margin-bottom: 18px;
}

.tableLoader {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.05);

  font-size: 38px;
  text-transform: uppercase;
  color: var(--color-blue);
}

.tableLoaderSpinner {
  width: 60px;
  height: 60px;
  background: none;
}

.tableTitle {
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 28px;
}

.table {
  width: 100%;
  border-collapse: collapse;

  & th {
    padding: 0 12px 12px;
    cursor: pointer;
  }

  & td {
    padding: 18px 12px;
    border: 1px solid rgba(206, 206, 214, 0.8);
  }
}

.tableTitleSpan {
  position: relative;

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    display: block;

    position: absolute;
    top: 50%;
    right: -6px;
    transform: translate(100%, -50%);

    background: red;
  }
}

.deleteBtn, .fixBtn {
  width: 20px;
  height: 20px;

  background: none;
  border: none;

  cursor: pointer;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.dateSelectBtn {
  width: 200px !important;
  height: 32px !important;
  padding: 4px 8px !important;
  font-size: 16px !important;
}

.pagination {
  width: fit-content;
  margin-left: auto;
}
