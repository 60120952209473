.header {
  height: max-content;
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}

.fixesBtn {
  height: max-content !important;
  padding: 4px 25px !important;
  margin-right: 16px !important;

  background: white !important;
  border: 1px solid var(--color-blue) !important;
  border-radius: 4px;
  color: black !important;
}

.tableContainer {
  position: relative;
  width: 100%;
  min-height: 90px;
  margin-bottom: 18px;
}

.tableLoader {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.05);

  font-size: 38px;
  text-transform: uppercase;
  color: var(--color-blue);
}

.tableLoaderSpinner {
  width: 60px;
  height: 60px;
  background: none;
}

.table {
  width: 100%;
  border-collapse: collapse;

  & th {
    padding: 0 12px 12px;
    cursor: pointer;
  }

  & td {
    padding: 18px 12px;
    border: 1px solid rgba(206, 206, 214, 0.8);
    white-space: nowrap;
  }
}

.deleteBtn {
  width: 20px;
  height: 20px;

  background: none;
  border: none;

  cursor: pointer;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.pagination {
  width: fit-content;
  margin-left: auto;
}
