.page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  background: #F3F3F6;
}

.form {
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 32px;

  background: white;
  border-radius: 16px;
}

.title {
  margin-bottom: 24px;
}

.input {
  width: 100%;
  padding: 14px 16px;
  margin-bottom: 16px;

  background: white;
  border-radius: 8px;
  border: 1px solid rgba(206, 206, 214, 0.8);

  font-size: 15px;
  line-height: 24px;

  &:focus {
    outline: none;
    border: 1px solid rgb(156, 156, 164);
  }
}

.error {
  width: 100%;
  position: absolute;
  top: 0;
  text-align: center;
  color: red;
}

.submitBtn {
  width: 100%;
  padding: 10px 0;
  margin: 28px 0 24px;

  background: #0063F7;
  border: none;
  border-radius: 8px;

  color: white;
  text-align: center;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;

  &:disabled {
    filter: grayscale(0.8) opacity(0.8);
  }
}

.logoutBtn {
  width: 160px;
  padding: 10px 0;
  margin: 28px 0 24px;

  background: white;
  border: 1px solid rgb(156, 156, 164);
  border-radius: 8px;

  color: var(--color-text);
  text-align: center;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
}
